import React, { useEffect, useState } from "react";
import styled from "styled-components";
import theme from "../../../../assets/theme";
import COLORS from "../../../../assets/Colors";
import { Paper, Grid, Typography } from "@material-ui/core";
import ActionBar from "../../../common/ActionBar";
import { navigate } from "@reach/router";
import _ from "lodash";
import { PrimaryCTAButton, SecondaryCTAButton } from "../../../common/Buttons";
import { Formik } from "formik";
import SystemService from "../../../../services/SystemService";
import useLoader from "../../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { AddCircleOutline } from "@material-ui/icons";
import { CustomInputField } from "../../../common/FormInputs";
import { ipAddressWhiteListValidationSchema } from "../../../../helpers/validationSchema";

const inputs = [
  {
    type: "text",
    name: "ipAddress",
    label: "Ip Address WhiteList",
    required: true,
  },
];

const IpAddressWhitelist = () => {
  const [ipItems, setIpItems] = useState([]);
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [trigger, setTrigger] = useState(true);

  const initialState = {
    ipAddress: "",
  };

  useEffect(() => {
    async function getIpAddressWhiteLists() {
      try {
        setLoader({ state: true, message: "Fetching details..." });
        const response = await SystemService.getIpAddressWhiteLists();
        if (response) {
          setIpItems(response?.IpAddressWhitelistDetails);
        }
      } catch (err) {
        throw err;
      } finally {
        setTrigger(false);
        setLoader({ state: false });
        window.scrollTo(0, 0);
      }
    }
    if (trigger) {
      getIpAddressWhiteLists();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "configurations":
        navigate("/dashboard/configurations");
        break;
      default:
        break;
    }
  };

  const addIpItems = (values, setFieldValue) => {
    const new_items = [
      ...values?.items,
      {
        ipAddress: "",
        addItem: true,
      },
    ];
    setFieldValue("items", new_items);
  };

  /**
   * @description delete ip whitelist
   */

  const deleteIpAddressWhiteLists = async (
    id,
    index,
    setFieldValue,
    values,
  ) => {
    try {
      setLoader({ state: true, message: "delete item..." });
      const new_items = [...values?.items].filter((ci, idx) => idx !== index);
      if (id) {
        const response = await SystemService.deleteIpAddressWhiteLists(id);
        if (response) {
          enqueueSnackbar(response?.message, {
            variant: "success",
          });
        }
      }
      setFieldValue("items", new_items);
    } catch (err) {
      enqueueSnackbar(err?.message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  };

  async function onFormSubmit(values, { setFieldValue }) {
    try {
      setLoader({ state: true, message: "create ip whitelist..." });
      let filteredItems = values?.items
        .filter((arr) => arr?.addItem)
        .map(({ addItem, ...item }) => item)[0];
      const payload = {
        ipAddressParams: [{ ipAddress: filteredItems?.ipAddress }],
      };
      const response = await SystemService.ipAddressWhiteList(payload);
      if (response) {
        enqueueSnackbar(response?.message, {
          variant: "success",
        });
      }
      setFieldValue(
        "items",
        values?.items.map((item) => ({ ...item, addItem: "" })),
      );
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  /**
   * @description update ip whitelist
   */

  async function updateIpAddressWhiteLists(id, ipAddress) {
    try {
      setLoader({ state: true, message: "update ip whitelist..." });
      const payload = {
        ipAddress: ipAddress,
      };
      const response = await SystemService.updateIpAddressWhiteLists(
        id,
        payload,
      );
      if (response) {
        enqueueSnackbar(response?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  return (
    <OuterContainer>
      <ActionBar
        breadcrumbs={["Ip address whitelist"]}
        {...{ onBreadcrumbClick }}
      />
      <Container>
        <Formik
          initialValues={{
            items: ipItems?.length
              ? ipItems?.map((item) => ({
                  ...item,
                  id: item?.id,
                  ipAddress: item?.ipAddress,
                }))
              : [initialState] || [initialState],
          }}
          onSubmit={onFormSubmit}
          enableReinitialize
          validateOnBlur
          validateOnChange
          validateOnMount
          validationSchema={ipAddressWhiteListValidationSchema}
        >
          {({
            values,
            handleChange,
            errors,
            handleSubmit,
            touched,
            setFieldError,
            setFieldValue,
            handleBlur,
            isValid,
            setFieldTouched,
          }) => (
            <FormContainer>
              <Container>
                <Paper
                  elevation={3}
                  style={{
                    padding: "16px",
                  }}
                >
                  <Heading>Ip address whitelist</Heading>
                  <Paper
                    style={{
                      padding: "16px",
                      width: "810px",
                    }}
                  >
                    {values?.items.map((arr, index) => (
                      <div
                        style={{
                          padding: 16,
                          // border: "1px solid #e1e3ee",
                          marginTop: "15px",
                          marginBottom: "15px",
                        }}
                      >
                        {inputs.map((input, idx) => (
                          <Grid
                            input
                            xs={12}
                            key={input.name}
                            style={styles.gridItem}
                          >
                            <CustomInputField
                              key={idx}
                              className={"input-white"}
                              value={arr[input.name]}
                              name={`items[${index}].ipAddress`}
                              onChange={handleChange}
                              variant="outlined"
                              onBlur={handleBlur}
                              style={{ width: "60%" }}
                              error={
                                errors?.items?.[index]?.ipAddress &&
                                touched?.items?.[index]?.ipAddress
                              }
                              helperText={
                                errors?.items?.[index]?.ipAddress &&
                                touched?.items?.[index]?.ipAddress
                              }
                            />
                          </Grid>
                        ))}
                        <ErrorText>
                          {touched?.items?.[index]?.ipAddress &&
                            errors?.items?.[index]?.ipAddress}
                        </ErrorText>
                        {values.items.length > 0 && (
                          <Grid
                            item
                            xs={12}
                            style={{
                              textAlign: "left",
                              marginTop: "15px",
                            }}
                          >
                            <PrimaryCTAButton
                              style={{
                                width: "auto",
                                height: "10px",
                                padding: "12px 12px",
                                display: !arr?.addItem ? "none" : "",
                              }}
                              disabled={!arr?.addItem}
                              onClick={handleSubmit}
                            >
                              Create
                            </PrimaryCTAButton>
                            <PrimaryCTAButton
                              style={{
                                marginLeft: !arr?.addItem ? 0 : 18,
                                width: "auto",
                                height: "10px",
                                padding: "12px 12px",
                              }}
                              onClick={() =>
                                updateIpAddressWhiteLists(arr.id, arr.ipAddress)
                              }
                            >
                              Update
                            </PrimaryCTAButton>
                            <PrimaryCTAButton
                              style={{
                                marginLeft: 18,
                                width: "auto",
                                height: "10px",
                                border: "1px solid #d91b1b",
                                backgroundColor: "#d91b1b",
                                padding: "12px 12px",
                              }}
                              onClick={() =>
                                deleteIpAddressWhiteLists(
                                  arr.id,
                                  index,
                                  setFieldValue,
                                  values,
                                )
                              }
                            >
                              {"Delete"}
                            </PrimaryCTAButton>
                          </Grid>
                        )}
                      </div>
                    ))}
                  </Paper>
                  <SecondaryCTAButton
                    onClick={() => addIpItems(values, setFieldValue)}
                    style={{ marginLeft: 30, marginTop: "10px" }}
                  >
                    <AddCircleOutline
                      style={{ marginRight: "10px", marginLeft: "5px" }}
                    />{" "}
                    Add
                  </SecondaryCTAButton>
                </Paper>
              </Container>
            </FormContainer>
          )}
        </Formik>
      </Container>
    </OuterContainer>
  );
};

const styles = {
  container: {
    backgroundColor: "#A9A9A9", // gray background
    padding: "20px",
    borderRadius: "8px",
  },
  gridItem: {
    display: "flex",
    // justifyContent: "space-between",
  },
  label: {
    fontWeight: "bold",
  },
};

export default IpAddressWhitelist;

export const OuterContainer = styled.div`
  border-radius: 10px;
  background-color: ${COLORS.PRIMARY_WHITE};
  margin: 40px 15px;
  padding-top: 1px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
    padding-top: 0;
    border-radius: 0;
    background-color: transparent;
  }
`;

const Container = styled.div`
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & .delete {
    display: flex;
    cursor: pointer;
  }
  @media ${theme?.breakpoints?.sm_up} {
    padding: 34px 43px;
  }
`;

export const FormControl = styled.div`
  margin-bottom: 13px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 50%;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;

export const ButtonWrapper = styled.div`
  width: fit-content;
  @media ${theme?.breakpoints?.md_up} {
    justify-content: flex-start;
    display: flex;
    width: 200px;
    padding-bottom: 20px;
    padding-top: 7px;
  }
`;

export const DeleteIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-top: 15px;
  object-fit: contain;
  cursor: pointer;
  margin-left: 10px;
`;

const FormContainer = styled.form`
  /* width: 907px; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media ${theme?.breakpoints?.md_up} {
    width: 100%;
  }
`;

const Heading = styled(Typography)(() => ({
  backgroundColor: "#9ABF80",
  color: "#fff",
  padding: "8px",
  fontFamily: theme.fonts.primaryFontBold,
  borderRadius: "5px",
}));

const ErrorText = styled.span`
  font-size: 10px;
  color: #f44336;
  font-family: openSans-SemiBold, sans-serif;
  bottom: -16px;
  left: 3px;
`;
