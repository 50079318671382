import labels from "../../../helpers/labels.json";

export const sidebar_options = [
  {
    icon: require("../../../assets/images/casemanager.svg"),
    inactive: require("../../../assets/images/casemanager.svg"),
    title: labels.case_manager,
    path: "/dashboard",
  },
  {
    icon: require("../../../assets/images/my_cases.svg"),
    inactive: require("../../../assets/images/casesInactive.svg"),
    title: labels.cases,
    path: "/dashboard/cases",
    listItems: [
      {
        icon: require("../../../assets/images/negotiation-active.svg"),
        inactive: require("../../../assets/images/negotiation-inactive.svg"),
        title: labels.cases_negotiation,
        path: "/dashboard/cases?caseType=negotiation",
        countKey: "negotiationCount",
      },
      {
        icon: require("../../../assets/images/mediation-active.svg"),
        inactive: require("../../../assets/images/mediation-inactive.svg"),
        title: labels.cases_mediation,
        path: "/dashboard/cases?caseType=mediation",
        countKey: "mediationCount",
      },
      {
        icon: require("../../../assets/images/mediation-active.svg"),
        inactive: require("../../../assets/images/mediation-inactive.svg"),
        title: labels.cases_arbitration,
        path: "/dashboard/cases?caseType=arbitration",
        countKey: "arbitrationCount",
      },
    ],
  },
  {
    icon: require("../../../assets/images/my_parties.svg"),
    inactive: require("../../../assets/images/partiesInactive.svg"),
    title: labels.mediator,
    path: "/dashboard/mediator",
  },
  {
    icon: require("../../../assets/images/config.png"),
    inactive: require("../../../assets/images/configInactive.png"),
    title: labels.server_room,
    path: "/dashboard/server-room",
  },
  {
    icon: require("../../../assets/images/payments.svg"),
    inactive: require("../../../assets/images/paymentsInactive.svg"),
    blueIcon: require("../../../assets/images/Payment History_active_Blue.svg"),
    title: labels.payments,
    path: "/dashboard/payments",
  },
  {
    icon: require("../../../assets/images/config.png"),
    inactive: require("../../../assets/images/configInactive.png"),
    title: labels.configurations,
    path: "/dashboard/configurations",
  },
  {
    icon: require("../../../assets/images/config.png"),
    inactive: require("../../../assets/images/configInactive.png"),
    title: labels.meeting_details,
    path: "/dashboard/recordings",
  },
  {
    icon: require("../../../assets/images/my_cases.svg"),
    inactive: require("../../../assets/images/casesInactive.svg"),
    title: labels.daily_casuse,
    path: "/dashboard/daily-cause",
  },
  {
    icon: require("../../../assets/images/config.png"),
    inactive: require("../../../assets/images/configInactive.png"),
    title: labels.ip_address_whitelist,
    path: "/dashboard/ip-address-whitelist",
  },
];
